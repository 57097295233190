@tailwind base;
@tailwind components;
@tailwind utilities;

input::placeholder,
textarea::placeholder {
    color: black;
}

@media (max-width: 760px) {
    .INDMobile #INDmenu-btn {
        height: 66px;
        top: 20px !important;
        bottom: 0px !important;
    }
}

@media (min-width: 761px) {
    .INDDesktop #INDmenu-btn {
        height: 66px;
        top: 140px !important;
        bottom: 0px !important;
        float: left !important;
        direction: ltr !important;
        right: auto !important;
        left: -62px;
    }
}

.w-screen {
    width: 100% !important;
}
